module.exports = function ({ addUtilities }) {

  addUtilities({
    '.content-scrollbar': {
      'scrollbar-width': 'auto',
      'scrollbar-color': 'var(--color-contentScrollbar) #ffffff',
    },

    '.content-scrollbar::-webkit-scrollbar': {
      'width': '12px',
      'height': '12px',
    },

    '.content-scrollbar::-webkit-scrollbar-track': {
      'background': 'transparent',
    },

    '.content-scrollbar::-webkit-scrollbar-thumb': {
      'background-color': 'var(--color-contentScrollbar)',
      'border-radius': '6px',
    }
  });
}

