import { useState } from 'react';
import '../index.css';
import Layout from '../components/Layout/Layout';
import store from '/store/store';
import { Provider } from 'react-redux';
import PageTransition from '@/components/PageTransition/PageTransition';
import { COMPLETIONSTATEMENT_TYPES } from '@babel/types';
// import { useRouter } from 'next/router';
// import { getUserOrRedirect } from 'utils/checkUser';

const layoutsMap = {
    ArticlePage: 'defaultLayout',
    BasePage: 'appLayout',
    CategoryPage: 'appLayout',
    CollectionIndexPage: 'appLayout',
    CollectionPage: 'appLayout',
    HomePage: 'defaultLayout',
    NotFoundPage: 'defaultLayout',
    PasswordProtectedPage: 'appLayout',
    PureHtmlPage: 'appLayout',
    ResourceIndexPage: 'appLayout',
    ResourceItemPage: 'appLayout',
    SectionPage: 'appLayout',
    PayItForwardPage: 'appLayout',
    // SearchPage: 'appLayout',
    StylesPage: 'appLayout',
    // AccountPage: 'appLayout',
    // FavouritesPage: 'appLayout',
    // PayItForward: 'appLayout',
    EssentialsPage: 'appLayout',
};

const securePage = {
    AccountPage: true,
};

function MyApp({ Component, pageProps }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (<>
        {(layoutsMap[Component.name] === 'appLayout' || layoutsMap[pageProps.componentName] === 'appLayout') && (
            <Provider store={store}>
                <Layout
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    navSections={pageProps.navSections}
                    exploreSelected={pageProps.componentName == 'SectionPage'}>
                    <Component {...pageProps} />
                </Layout>
            </Provider>
        )}

        {!(layoutsMap[Component.name] === 'appLayout' || layoutsMap[pageProps.componentName] === 'appLayout') && (
            <Provider store={store}>
                <Component
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    navSections={pageProps.navSections}
                    exploreSelected={pageProps.componentName == 'SectionPage'}
                    {...pageProps}
                />
            </Provider>
        )}

        {/* <PageTransition /> */}
        </>
    )
}

export default MyApp;
