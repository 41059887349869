import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from '@/utils/get';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

function Select({
    bgColor,
    textColor,
    bgColorBox,
    textColorBox,
    onChange,
    options,
    displayKey,
    initialOption,
    label,
    value,
    className,
    buttonClass,
    boxClass,
}) {
    // Create the options array appending an Initial option if it has been passed in. initialOption can be use to check if it is the created option
    const selectOptions = () => {
        if (initialOption) {
            return [
                {
                    [displayKey]: initialOption,
                    initialOption: true,
                },
                ...options,
            ];
        }
        if (!initialOption) {
            if (options !== undefined) {
                return [...options];
            } else {
                return [];
            }
        }
    };

    const [selected, setSelected] = useState(value ? value : selectOptions()[0]);

    // Handle value being set by parent
    useEffect(() => {
        if (value) setSelected(value);
    }, [value]);

    // Handle user input
    function handleChange(val) {
        setSelected(val);
        if (onChange) onChange(val);
    }

    return (
        <Listbox value={selected} onChange={handleChange}>
            <div className={`text-${textColor || 'current'} relative mt-1 text-small-copy ${className}`}>
                <Listbox.Button
                    className={`bg-${
                        bgColor || 'mango'
                    } relative w-full py-1 pl-3 pr-8 text-left rounded-full cursor-default whitespace-nowrap focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm ${buttonClass}`}>
                    {({ open }) => (
                        <>
                            <span className="block truncate">
                                {/* If label exists, show the label as the default value and as a prefix for other values */}
                                {label && (selected[displayKey] == selectOptions()[0][displayKey] ? label : `${label}: ${get(selected, displayKey)}`)}
                                {/* If label doesn't exist, show the value */}
                                {!label && get(selected, displayKey)}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronDownIcon
                                    className={`w-5 h-5 transition-transform origin-center duration-200 ${open ? 'rotate-180' : 'rotate-0'}`}
                                    aria-hidden="true"
                                />
                            </span>
                        </>
                    )}
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Listbox.Options
                        className={`bg-${bgColorBox ? bgColorBox : bgColor || 'mango'} text-${textColorBox ? textColorBox : textColor}
                        absolute py-1 mt-2 w-full overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${boxClass}`}>
                        {selectOptions().length &&
                            selectOptions().map((option, optionIndex) => (
                                <Listbox.Option
                                    key={optionIndex}
                                    className={({ active }) => `cursor-default select-none relative py-2 pl-10 pr-4`}
                                    value={option}>
                                    {() => (
                                        <>
                                            <span className={`block truncate text-small-copy`}>{get(option, displayKey)}</span>
                                            {selected[displayKey] === option[displayKey] ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-mango-600">
                                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
}

Select.propTypes = {
    bgColor: PropTypes.string, // Background colour for the button and the box
    textColor: PropTypes.string, // Text color for the button and the box
    bgColorBox: PropTypes.string, //Overides BG color for the dropdown box. Otherwise will just default to bg color
    textColorBox: PropTypes.string, //Overides text color for the dropdown box. Otherwise will just default to bg color
    onChange: PropTypes.func, // Function to call in the parent on change
    options: PropTypes.array.isRequired, // The list of options
    value: PropTypes.object, // A value that can be passed in from the parent on load or whenever.
    displayKey: PropTypes.string.isRequired, //This can be a selector style string 'resource_type.name' or a single key 'name'
    initialOption: PropTypes.string, // An initial option that is inserted into the options array if present
    label: PropTypes.string, // An optional text string that replaces the first option and acts as as a prefix for subsequent options e.g. 'Type' -> 'Type: Audio'
    className: PropTypes.string, // Classes to add to the wrapper around the whole component
    buttonClass: PropTypes.string, // Classes added only to the button handy for setting things like w-max or w-64 (don't put color classes here)
    boxClass: PropTypes.string, // Clases added only to the drop down box for setting width etc. (don't put color classes here)
};

export default Select;
