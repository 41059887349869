module.exports = {
    content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './containers/**/*.{js,ts,jsx,tsx}'],
    safelist: [
        'group-hover:bg-mango',
        'group-hover:bg-sky',
        'group-hover:bg-peach',
        'group-hover:bg-ocean',
        'group-hover:bg-sunset',
        'group-hover:bg-granite',
        'group-hover:bg-marsh',
        'group-hover:bg-terracotta',
        'group-hover:bg-moss',
        'group-hover:bg-cream',
        'group-hover:bg-heather',
        'group-hover:bg-offwhite',
        'group-hover:bg-sand',
    ],
    theme: {
        fontFamily: {
            sans: ['flexa', 'sans-serif'],
            tusker: ['tusker', 'sans-serif'],
        },
        extend: {
            fontSize: {
                base: ['16px', '22px'],
                'mobile-display': ['38px', '38px'],
                'desktop-display': ['70px', '70px'],
                'mobile-h1': ['26px', '32px'],
                'desktop-h1': ['38px', '49px'],
                'mobile-h2': ['24px', '30px'],
                'desktop-h2': ['40px', '50px'],
                'mobile-h3': ['14px', '17px'],
                'desktop-h3': ['18px', '22px'],
                'small-copy': ['14px', '18px'],
                'large-copy': ['18px', '24px'],
                'home-copy': ['22px', '28px'],
            },
            colors: {
                mango: '#FFC43C',
                sky: '#A0CCE6',
                peach: '#FEC2B1',
                ocean: '#7A9889',
                sunset: '#F4783E',
                granite: '#CABFAC',
                moss: '#B49D34',
                terracotta: '#CF614A',
                contentScrollbar: 'var(--color-heather)',
                cream: '#FCF7EE',
                heather: '#959494',
                marsh: '#966F16',
                offwhite: '#FCF7EE',
                sand: '#EBE4D7',
            },
            keyframes: {
                wiggle: {
                    '0%, 7%': { transform: 'rotateZ(0)' },
                    '15%': { transform: 'rotateZ(-12deg)' },
                    '20%': { transform: 'rotateZ(8deg)' },
                    '25%': { transform: 'rotateZ(-8deg)' },
                    '30%': { transform: 'rotateZ(4deg)' },
                    '35%': { transform: 'rotateZ(-2deg)' },
                    '40%, 100%': { transform: 'rotateZ(0)' },
                },
                'fade-in': {
                    '0%': { opacity: 0 },
                    '100%': { opacity: 1 },
                },
                'fade-out': {
                    '0%': { opacity: 1 },
                    '99%': { opacity: 0 },
                    '100%': { opacity: 0, display: 'none' },
                },
                'fade-in-up': {
                    '0%': { opacity: 0, transform: 'translateY(20px)' },
                    '50%': { opacity: 0, transform: 'translateY(20px)' },
                    '100%': { opacity: 1, transform: 'translateY(0px)' },
                },
                'fade-out-down': {
                    '0%': { opacity: 1, transform: 'translateY(0px)' },
                    '99%': { opacity: 0, transform: 'translateY(20px)' },
                    '100%': { opacity: 0, transform: 'translateY(20px)', display: 'none' },
                },
                'page-in': {
                    '0%': { opacity: 0, transform: 'translateY(50%)' },
                    '100%': { opacity: 1, transform: 'translateY(0%)' },
                },
                'page-out': {
                    '0%': { opacity: 1, transform: 'translateY(0%)' },
                    '100%': { opacity: 0, transform: 'translateY(50%)' },
                },
            },
            animation: {
                wiggle: 'wiggle 2s ease forwards',
                'fade-in': 'fade-in 0.4s ease forwards',
                'fade-out': 'fade-out 0.4s ease forwards',
                'fade-in-up': 'fade-in-up 1s ease forwards',
                'fade-out-down': 'fade-out-down 0.5s ease forwards',
                'page-in': 'page-in 0.2s ease forwards',
                'page-out': 'page-out 0.2s ease forwards',
            },
            typography: (theme) => ({
                DEFAULT: {
                    css: {
                        lineHeight: '22px',
                        h1: {
                            fontSize: '26px',
                            lineHeight: '32px',
                            fontFamily: 'tusker, sans-serif',
                            textTransform: 'uppercase',
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        h2: {
                            fontWeight: 'normal',
                            fontSize: '24px',
                            lineHeight: '30px',
                            marginBottom: '0.5em',
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        h3: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            lineHeight: '17px',
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        p: {
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        ul: {
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        ol: {
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        a: {
                            color: theme('colors.terracotta'),
                        },
                    },
                },
                lg: {
                    css: {
                        fontSize: '16px',
                        lineHeight: '22px',
                        h1: {
                            fontSize: '38px',
                            lineHeight: '49px',
                            fontFamily: 'tusker, sans-serif',
                            textTransform: 'uppercase',
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        h2: {
                            fontSize: '40px',
                            lineHeight: '50px',
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        h3: {
                            fontSize: '18px',
                            fontWeight: 'bold',
                            lineHeight: '22px',
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        p: {
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        ul: {
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        ol: {
                            '&:first-child': {
                                marginTop: '0',
                            },
                            '&:last-child': {
                                marginBottom: '0',
                            },
                        },
                        a: {
                            color: theme('colors.terracotta'),
                        },
                    },
                },
            }),
        },
    },
    plugins: [require('@tailwindcss/typography'), require('./utils/tailwindColorVars'), require('./utils/tailwindContentScrollbar')],
};
