import { useRouter } from 'next/router';
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { classNames } from '@/utils/classNames';
import Link from 'next/link';
import Select from '@/components/Select/Select';
import Logo from '@/components/Logo/Logo';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';
import { CurrencyDollarIcon, MapIcon, SearchIcon, UserIcon } from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';
import { httpGet } from '@/utils/Http';
import { setCurrentSection } from '/store/user/userSlice';
import { getUserOrRedirect } from '../../utils/checkUser';

// Provide colours for the navigation backkground and highlight colour
const tailwindConfig = require('../../tailwind.config.js');
const notTheseColors = ['offwhite', 'cream', 'contentScrollbar'];
const colors = Object.keys(tailwindConfig.theme.extend.colors).filter((color) => !notTheseColors.includes(color));

const selectColor = (i) => {
    return colors[i % colors.length];
};

function Layout(props) {
    getUserOrRedirect(props.children.props ? props.children.props : props.children[0].props);
    const user = useSelector((state) => state.user);
    const router = useRouter();
    const dispatch = useDispatch();

    const exploreLink = useSelector((state) => state.user.currentSection);
    const [navigation, setNavigation] = useState([
        { name: 'Explore', href: exploreLink?.value || '/explore', icon: MapIcon },
        { name: 'Search', href: '/search', icon: SearchIcon },
        { name: 'Favourites', href: '/favourites', icon: HeartIcon, iconColor: 'sunset' },
        { name: 'Subscription', href: '/subscription', icon: CurrencyDollarIcon },
        { name: user.firstName || 'Account', href: '/account', icon: UserIcon },
    ]);

    const [navColor, setNavColor] = useState('');

    const checkSection = (router) => {
        let currentRoute = router.asPath.split('/')[1];
        const route = props.navSections.filter((section) => {
            if (section.value.split('/')[1] === currentRoute) {
                return section;
            }
        });
        if (route.length > 0) dispatch(setCurrentSection(route[0]));
    };

    // useEffect(() => {
    //     // console.log(user.currentSection, props.navSections, navColor);

    //     setNavColor(selectColor(props.navSections.indexOf(user.currentSection)));
    // }, [user.currentSection]);

    useEffect(() => {
        if (user.currentSection) {
            setNavigation([
                { name: 'Explore', href: exploreLink?.value || '/explore', icon: MapIcon },
                { name: 'Search', href: '/search', icon: SearchIcon },
                { name: 'Favourites', href: '/favourites', icon: HeartIcon, iconColor: 'sunset' },
                { name: 'Subscription', href: '/subscription', icon: CurrencyDollarIcon },
                { name: user.firstName || 'Account', href: '/account', icon: UserIcon },
            ]);
            const currentNavSection = props.navSections.filter((x) => x.value == user.currentSection.value)[0];
            setNavColor(selectColor(props.navSections.indexOf(currentNavSection)));
            dispatch(setCurrentSection(user.currentSection));
        } else {
            checkSection(router);
        }
    }, [user]);

    function isActiveSection(router, item) {
        if (!router || !item) return;
        return router.asPath.split('/')[1] === item.href.split('/')[1];
    }
    const setSection = (section) => {
        dispatch(setCurrentSection(section));
        router.push(section.value);
        props.setSidebarOpen(false);
    };
    const handleNavClick = (e, href) => {
        e.preventDefault();
        props.setSidebarOpen(false);
        router.push(`${href}`);
    };

    return (
        <>
            <div className="px-3">
                <div className="flex flex-col flex-1 md:block">
                    {/* Main Header */}
                    <header className="sticky z-10 mb-8 top-3 lg:hidden">
                        <div className={`flex gap-1 items-center rounded-md bg-${navColor}`}>
                            <Link href={exploreLink?.value || '#'}>
                                <a>
                                    <Logo color={navColor} className="w-[110px] ml-1" />
                                </a>
                            </Link>
                            {user?.currentSection?.label && <span className="leading-none text-small-copy">{user.currentSection.label}</span>}
                            {/* Menu Button */}
                            <button
                                type="button"
                                className="inline-flex items-center justify-center px-3 ml-auto text-gray-900 rounded-md h-14 hover:text-gray-900 focus:outline-none focus:ring-transparent focus:ring-inset"
                                onClick={() => props.setSidebarOpen(!props.sidebarOpen)}>
                                <span className="mr-2">{`${props.sidebarOpen ? 'Close' : 'Menu'}`}</span>
                                {props.sidebarOpen ? <XIcon className="w-7 h-7" aria-hidden /> : <MenuIcon className="w-7 h-7" aria-hidden />}
                            </button>
                        </div>

                        {/* Mobile Nav Drawer */}
                        <Transition.Root show={props.sidebarOpen} as={Fragment}>
                            <div className="relative flex flex-col flex-1 w-full">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <div className="absolute z-40 flex-1 w-full pt-5 pb-4 rounded-md shadow-md top-2 bg-cream">
                                        <nav className="px-4 space-y-1">
                                            {user.currentSection?.label && (
                                                <Select
                                                    bgColor={navColor}
                                                    className="w-full mb-3"
                                                    options={props.navSections}
                                                    displayKey="label"
                                                    onChange={setSection}
                                                    value={user.currentSection}
                                                />
                                            )}

                                            {navigation.map((item) => {
                                                const activeSection = isActiveSection(router, item);
                                                return (
                                                    <a
                                                        key={item.name}
                                                        className="flex items-center group"
                                                        onClick={(e) => handleNavClick(e, item.href)}>
                                                        <span
                                                            className={classNames(
                                                                activeSection ? `bg-${navColor}` : '',
                                                                `transition ease-in-out flex items-center flex-shrink-0 w-9 h-9 mr-3 justify-center rounded-full group-hover:bg-${navColor} `
                                                            )}>
                                                            <item.icon className={classNames(activeSection ? '' : '', 'h-5 w-5')} aria-hidden />
                                                        </span>
                                                        {item.name}
                                                    </a>
                                                );
                                            })}
                                        </nav>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Transition.Root>
                    </header>

                    <main className="max-w-[1260px] md:mx-auto lg:pl-72">{props.children}</main>
                </div>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:w-56 lg:flex-col lg:fixed lg:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-1 min-h-0">
                        <div className="flex flex-col flex-1 pt-5 mb-10 overflow-y-auto">
                            <div className="flex flex-col items-stretch flex-shrink-0 pl-1">
                                <Link href={exploreLink?.value || '#'}>
                                    <a>
                                        <Logo className="mb-2 cursor-pointer" color={navColor} />
                                    </a>
                                </Link>
                                {user.currentSection?.label && (
                                    <Select
                                        bgColor={navColor}
                                        options={props.navSections}
                                        displayKey="label"
                                        onChange={setSection}
                                        value={user.currentSection}
                                    />
                                )}
                            </div>
                            <nav className="flex-1 pl-1 mt-5 space-y-1">
                                {navigation.map((item) => {
                                    const activeSection = isActiveSection(router, item);
                                    return (
                                        <Link key={item.name} href={item.href}>
                                            <a className="flex items-center group">
                                                <span
                                                    className={classNames(
                                                        activeSection ? `bg-${navColor}` : '',
                                                        `transition ease-in-out flex items-center flex-shrink-0 w-9 h-9 mr-3 justify-center rounded-full group-hover:bg-${navColor} `
                                                    )}>
                                                    <item.icon
                                                        className={classNames(
                                                            activeSection ? '' : '',
                                                            'h-5 w-5',
                                                            item.iconColor ? `text-${item.iconColor}` : ''
                                                        )}
                                                        aria-hidden
                                                    />
                                                </span>
                                                <span className="mt-1">{item.name}</span>
                                            </a>
                                        </Link>
                                    );
                                })}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layout;
