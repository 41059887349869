import Cookies from 'js-cookie';
import { httpGet } from './Http';

function getCookie(name) {
  return Cookies.get(name);
}

async function getCookieOrCreate(name) {
    const cookie = getCookie(name);
    if (cookie) {
        return cookie;
    }

    const response = await httpGet(`${process.env.NEXT_PUBLIC_AUTH_API_URL}-test/`);
    return getCookie(name)
}

export { getCookie, getCookieOrCreate }