export default function (props) {
    return (
        <div className={`bg-${props.color || 'mango'} ${props.className} rounded-3xl`}>
            <svg viewBox="0 0 215 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* <rect x="215" y="0.447021" width="81.6609" height="214.195" rx="20" transform="rotate(90 215 0.447021)" fill="#FFC43B"/> */}
                <path d="M33.1032 50.9849H43.9363L38.5198 46.4465L33.1032 50.9849Z" fill="black" />
                <path d="M24.1001 58.5288L18.6357 63.1078H58.404L52.9396 58.5288H24.1001Z" fill="black" />
                <path d="M25.8669 57.0469H51.1682L45.7032 52.4679H31.3319L25.8669 57.0469Z" fill="black" />
                <path d="M24.3538 34.5779L18.1055 39.8136V45.7908L27.9206 37.5665L24.3538 34.5779Z" fill="black" />
                <path d="M52.6843 34.5771L49.1174 37.5663L58.9332 45.7907V39.8129L52.6843 34.5771Z" fill="black" />
                <path d="M29.0751 38.5334L18.1055 47.7253V53.7038L32.6419 41.5226L29.0751 38.5334Z" fill="black" />
                <path d="M44.3964 41.5226L58.9335 53.7038V47.7253L47.9633 38.5334L44.3964 41.5226Z" fill="black" />
                <path d="M33.7971 42.49L18.1055 55.6387V61.6165L37.3639 45.4792L33.7971 42.49Z" fill="black" />
                <path d="M43.2412 40.5551L57.4109 28.6816L53.8441 25.6924L39.6744 37.5659L43.2412 40.5551Z" fill="black" />
                <path d="M19.6267 28.682L58.9313 61.6165V55.6386L23.1935 25.6934L19.6267 28.682Z" fill="black" />
                <path d="M43.2412 32.6428L52.6896 24.7254L49.1221 21.7368L39.6744 29.6536L43.2412 32.6428Z" fill="black" />
                <path d="M42.0873 33.6102L27.9182 21.7373L24.3508 24.7259L38.5205 36.5994L42.0873 33.6102Z" fill="black" />
                <path d="M47.9676 20.7691L44.4008 17.7805L39.6744 21.7411L43.2406 24.7297L47.9676 20.7691Z" fill="black" />
                <path d="M42.0853 25.6966L32.6376 17.7805L29.0708 20.7691L38.5185 28.6858L42.0853 25.6966Z" fill="black" />
                <path
                    d="M104.46 62.4975H88.9175L84.1567 44.2012H83.3633V62.4975H69.1743V18.3904H83.4099L83.3633 37.0134H84.1567L88.9641 18.3904H103.9L97.3188 39.5338L104.46 62.4975Z"
                    fill="black"
                />
                <path
                    d="M120.374 50.6889H133.676V62.4975H106.185V18.3904H133.443V30.199H120.374V34.6797H132.696V45.7415H120.374V50.6889Z"
                    fill="black"
                />
                <path d="M166.157 18.3904V30.1056H157.849V62.4975H143.66V30.1523H135.259V18.3904H166.157Z" fill="black" />
                <path
                    d="M182.591 50.6889H195.893V62.4975H168.402V18.3904H195.66V30.199H182.591V34.6797H194.913V45.7415H182.591V50.6889Z"
                    fill="black"
                />
            </svg>
        </div>
    );
}
