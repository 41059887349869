import { createSlice } from '@reduxjs/toolkit';

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        regions: [
            { name: 'Northland (Te Tai Tokerau)' },
            { name: 'Auckland (Tāmaki-makau-rau)' },
            { name: 'Waikato' },
            { name: 'Bay of Plenty (Te Moana-a-Toi)' },
            { name: 'Gisborne (Te Tai Rāwhiti)' },
            { name: "Hawke's Bay (Te Matau-a-Māui)" },
            { name: 'Taranaki' },
            { name: 'Manawatū-Whanganui' },
            { name: 'Wellington (Te Whanga-nui-a-Tara)' },
            { name: 'Tasman (Te Tai-o-Aorere)' },
            { name: 'Nelson (Whakatū)' },
            { name: 'Marlborough (Te Tauihu-o-te-waka)' },
            { name: 'West Coast (Te Tai Poutini)' },
            { name: 'Canterbury (Waitaha)' },
            { name: 'Otago (Ōtākou)' },
            { name: 'Southland (Murihiku)' },
        ],
        howDidYouHear: [
            { name: 'Friend' },
            { name: 'Family' },
            { name: 'Collegue' },
            { name: 'Facebook' },
            { name: 'Twitter' },
            { name: 'Instagram' },
            { name: 'Google' },
            { name: 'Newspaper' },
            { name: 'Radio' },
            { name: 'Television' },
            { name: 'Internet' },
            { name: 'Other' },
        ],
    },
    reducers: {},
});

// Action creators are generated for each case reducer function
// export const {
//  } = settingSlice.actions

export default settingSlice.reducer;
