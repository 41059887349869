export const get = function (obj, path, defValue) {
    // Copied from https://youmightnotneed.com/lodash/#get
    if (!path) return undefined;
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    const result = pathArray.reduce(
        (prevObj, key) => prevObj && prevObj[key],
        obj
    );
    return result === undefined ? defValue : result;
};
